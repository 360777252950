.heading{
    font-family: 'Raleway',sans-serif;
    font-weight: 900;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
}
.buscador{
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
}
.btn_block{
    width: 100%;
}